import React from "react";
import { Link } from "gatsby";
import { DefaultLayout, SidebarLayout } from "../layouts";
import { Hero } from "../components";
import { Body } from "../layouts/SidebarLayout/SidebarLayout";

export const NotFoundTemplate = () => (
  <DefaultLayout>
    <Hero showBox={false} image="" />

    <SidebarLayout
      body={
        <Body>
          <h1>Not Found</h1>
          <Link to="/">Back home</Link>
        </Body>
      } sidebar=""
    />
  </DefaultLayout>
);

export default () => (
  <NotFoundTemplate />
);
